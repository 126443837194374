/**
 * Do not remove any keys!!
 * The shape of this file is to remain the same so that all options are know.
 *
 * If a key does not have a value, set the value to undefined.
 */
module.exports = {
  communityName: "The Brickyard",
  gtagId: undefined,
  logo: {
    src: "https://cdn.maxxpm.com/dublinfarms/logosandbanners/Dublin Farms GR logo@0.5x.png",
    alt: "Dublin Farms Logo",
  },
  nav: {
    bannerSubtitle: undefined,
    bannerTitle: undefined,
    bannerCTA: undefined,
  },
  footer: {
    address: "4282 East Parnell Lane",
    cityStateZip: "Eagle Mountain, UT 84005",
    phone: "(385) 236-4903",
    copyrightYear: "2024",
    logo: "https://cdn.maxxpm.com/dublinfarms/logosandbanners/Dublin Farms GR logo@0.5x.png",
  },
  listMarkerSrc: "https://cdn.maxxpm.com/dublinfarms/logosandbanners/Dublin Farms bullet point 7px.png",
  hasCalendlyPopup: false,
  calendlyLink: undefined,
  home: {
    banner: {
      image: "https://cdn.maxxpm.com/dublinfarms/logosandbanners/Eagle Mountain.jpg",
      video: undefined,
      title: "Stylish Upgraded Townhomes",
      subtitle: "Welcome Home!",
    },
    aboutUs: {
      paragraphs: [
        "Dublin Farms Townhomes is a beautiful community located in the Ranches Development in Eagle Mountain, UT. Onsite amenities include a premier fitness center, an exclusive clubhouse, and expansive recreational space. Additionally, the convenient location is just 15 minutes from I-15 and allows quick access to a variety of first-rate entertainment, world-class outdoor activities, and delicious restaurants. Those living in Dublin Farm Townhomes also enjoy beautiful views of the Wasatch Mountain Range and Utah Lake year-round.",
        "With 3 unique townhome layouts to offer, Dublin Farms is bound to have something that fits your lifestyle. Our townhomes offer gorgeous open-concept layouts and state-of-the-art appliances at an affordable price. We can't wait to welcome you home!",
      ],
      image: {
        src: "https://cdn.maxxpm.com/stockphotos/living+room+grey+couch.jpg",
        alt: "Staged Living Room",
      },
    },
    floorplanTitle: "Explore Our Floor Plans and Take a 3D Virtual Tour",
    floorplans: [
      {
        hasImage: true,
        image: {
          src: "https://cdn.maxxpm.com/dublinfarms/3storypayton/Payton-01.jpg",
          alt: "Payton Model Floor Plan",
        },
        hasWalkthrough: true,
        walkthrough: {
          src: "https://my.matterport.com/show/?m=H5AC7vTrP4k",
          title: "Payton model 3d walkthrough",
        },
      },
      {
        hasImage: true,
        image: {
          src: "https://cdn.maxxpm.com/dublinfarms/2storydelinda/Delinda+floorplan.jpg",
          alt: "Delinda Model Floor Plan",
        },
        hasWalkthrough: true,
        walkthrough: {
          src: "https://my.matterport.com/show/?m=Rpwhu7yof4v",
          title: "Delinda model 3d walkthrough",
        },
      },
      {
        hasImage: true,
        image: {
          src: "https://cdn.maxxpm.com/dublinfarms/2storysteve/Steve.jpg",
          alt: "Steve Model Floor Plan",
        },
        hasWalkthrough: true,
        walkthrough: {
          src: "https://my.matterport.com/show/?m=zVdNrr5qez1",
          title: "Steve model 3d walkthrough"
        }
      },
    ],
    midContainerImage: "https://cdn.maxxpm.com/banners/Idaho.jpg",
    imgRow: [
      {
        src: "https://cdn.maxxpm.com/stockphotos/coffee+table+and+couch.jpg",
        alt: "Image banner stock photo",
      },
      {
        src: "https://cdn.maxxpm.com/stockphotos/boys+play+soccer.jpg",
        alt: "Image banner stock photo",
      },
      {
        src: "https://cdn.maxxpm.com/stockphotos/bedroom+circle+mirror.jpg",
        alt: "Image banner stock photo",
      },
    ],
    animalPolicy: {
      img: {
        src: "https://cdn.maxxpm.com/stockphotos/bulldog+on+legs.jpg",
        alt: "Animal policy stock photo",
      },
      policies: [
        "No more than 2 animals are allowed per property",
        "Cats/Dogs: $250/animal*",
        "$50/animal/month for animals under 50lbs*",
        "$75/animal/month for animals 50lbs+*",
        "One-time $75/animal DNA testing fee*",
        "Caged animals: $25/animal/month*",
        "Fish tanks are not permitted",
        "Rabbits and rodents of any kind are not permitted",
      ],
      breedRestrictions: [
        "Pit Bulls & Staffordshire Terriers",
        "Doberman Pinschers",
        "Rottweilers",
        "Chows",
        "Great Danes",
        "Presa Canarios/ Mastiffs",
        "Akitas",
        "Alaskan Malamutes",
        "Huskies",
        "German Shepherds",
        "Shar Peis",
        "Wolf-Hybrids",
      ],
    },
  },
  availability: {
    banner: {
      image: "https://cdn.maxxpm.com/dublinfarms/logosandbanners/Eagle Mountain.jpg",
      video: undefined,
      title: "Available Units",
      subtitle:
        "Unit availability is updated often from Monday-Friday during business hours. Evenings and weekends may not reflect accurate availability dates.",
    },
    hasWaitingList: false,
    waitingListJotformId: undefined,
    propertyGroup: "LEADS-DF",
  },
  faq: {
    banner: {
      image: "https://cdn.maxxpm.com/lagunafarms/logoandbanners/girls+on+bed+with+dog.jpg",
    },
    dnaRequired: true,
  },
};
